import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import Datepicker from '@/components/Date/Default/index.vue'

export default defineComponent({
  props: {
    entity: {
      type: String,
      default: 'coef',
    },
    single: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Datepicker,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const model = ref(false)

    const mask = (val) => {
      val = val?.toString()
      if (val && (val.length <= 1 || val[0] === '1')) return 'M'
      else return 'M.##'
    }

    const getUnix = (val) => {
      return moment(val, 'YYYY-MM-DD').unix()
    }

    const formTemplate = {
      1: {
        switch: {
          value: false,
          disabled: false,
          label: 'Аванс | Зарплата',
        },
        data: {
          entityValue: '',
          date_from: '',
          date_to: '',
        },
      },
      2: {
        switch: {
          value: false,
          disabled: false,
          label: 'Ежедневная',
        },
        data: {
          entityValue: '',
          date_from: '',
          date_to: '',
        },
      },
    }
    const form = ref(_.cloneDeep(formTemplate))

    const changeEntity = () => {
      console.log('changeEntity')
    }

    watch(
      () => model.value,
      () => {
        if (model.value) {
          form.value = _.cloneDeep(formTemplate)
          if (props.single) {
            if (props.single === 1) form.value[2].data = undefined
            else form.value[1].data = undefined
            form.value[props.single].switch.disabled = true
            form.value[props.single].switch.value = true
          }
        }
      }
    )

    const disabled = ref(false)
    watch(
      () => form.value,
      () => {
        const singleTrue = Object.values(form.value).some((item) => {
          return item.switch.value
        })
        for (let i = 0; i < Object.values(form.value).length; i++) {
          const item = Object.values(form.value)[i]
          if (!singleTrue) {
            disabled.value = true
            return
          } else if (item.data && item.switch.value) {
            if (
              !(
                item.data.date_from &&
                item.data.date_to &&
                getUnix(item.data.date_to) >= getUnix(item.data.date_from) &&
                item.data.entityValue
              )
            ) {
              disabled.value = true
              return
            }
          }
        }
        disabled.value = false
      },
      { deep: true }
    )

    return {
      mask,
      getUnix,
      changeEntity,

      model,
      form,
      disabled,
    }
  },
})
