var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"flat":"","accordion":"","multiple":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((_vm.proxyValue),function(zone){return _c('v-expansion-panel',{staticClass:"pactPanel"},[_c('v-expansion-panel-header',{staticClass:"px-3 py-0",staticStyle:{"min-height":"56px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(zone.loaded === false)?_c('v-progress-circular',{attrs:{"color":"primary","size":22,"indeterminate":""}}):_vm._e()]},proxy:true}],null,true)},[_c('v-row',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"text-cut",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"text--text",attrs:{"title":zone.name}},[_vm._v(_vm._s(zone.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3"}),_c('Coefficient',{staticClass:"mr-3",attrs:{"coef":zone.coefficient},on:{"changeEntity":(e) =>
                _vm.$emit('changeEntity', e, {
                  type: 'zone',
                  item: { ...zone, territory_id: _vm.territory.id },
                  key: 'zone_id',
                  parent: _vm.type,
                })}}),_c('history',{attrs:{"entity":{
              type: 'zone',
              id: zone.id,
            }}})],1)])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }