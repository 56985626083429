import SwitchDefault from '@/components/Switch/default/index.vue'
import Vue, { onMounted, computed, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import Pact from './../pact/index.vue'
import Zone from './../zone/index.vue'
import useRequest from '@/compositions/useRequest'
import { useRouter, useRoute } from 'vue-router/composables'
import parserTarif from '@/pages/object/config/form-object-parser'
import territoryAdd from '@/pages/x5territories/config/form-territories-add-edit'
import zoneAdd from '@/pages/x5zone/config/custom-zone-add-edit'
import Detail from '@/components/Table/detail/index.vue'
import Autocomplete from '@/components/Autocomplete/default'
import FormDefault from '@/components/Form/default/index.vue'
import Coefficient from './../coefficient'
import Tarif from './../tarif'
import moment from 'moment/moment'
import history from '@/pages/object/config/custom-object-tarif/history'
// import FormOutput from '@/components/Form/output/correct/index.vue'

export default {
  name: 'Contract',
  props: {},
  components: {
    SwitchDefault,
    Pact,
    Zone,
    Detail,
    Autocomplete,
    FormDefault,
    Coefficient,
    history,
    Tarif,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(true)
    const types = ref([])
    const parser = ref(false)
    const expansion = ref([])

    const switchBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Зона', value: 1 },
    ]

    const formTemplate = {
      isShow: false,
      component: null,
      type: null,
      entity: '',
      zone: null,
      contract: null,
    }
    const form = ref(_.cloneDeep(formTemplate))

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: (data) =>
        store.dispatch('list/get', [
          { alias: 'agreement_dictionary', filter: [] },
        ]),
    })

    const refresh = () => {
      if (form.value.entity === 'territory') {
        getTerritories(
          types.value.findIndex((x) => x.id === form.value.type.id),
          true
        )
      } else if (form.value.entity === 'zone') {
        form.value.type.data.items.splice(form.value.zone.index, 1)
        getContracts(form.value.type)
      } else if (form.value.entity === 'contract') {
        form.value.type.data.items.splice(form.value.contract.index, 1)
        getContracts(form.value.type)
      }
    }

    const getTypes = async () => {
      const response = await store.dispatch(
        'form/get',
        'get/object/contract/types'
      )
      response.data.forEach((item) => {
        Vue.set(item, 'data', {
          loaded: null,
          loading: false,
          territories: [],
          items: [],
          active: [0],
          lastTarget: 0,
          docType: 0,
        })
      })
      types.value = response.data
      loading.value = false
    }

    const getTerritories = async (index, refresh = false) => {
      const type = types.value[index]
      if (!refresh) {
        if (type.data.loaded !== true)
          expansion.value = _.without(expansion.value, index)
        if (type.data.loaded !== null) return
      }
      type.data.loaded = false
      const response = await store.dispatch(
        'form/get',
        `get/territories/${type.id}`
      )
      type.data.territories = response.data
      type.data.loaded = true
      if (!refresh) expansion.value.push(index)
      if (type.data.territories.length) getContracts(type)
    }

    const changeTerritory = ({ index, data, btn, type }) => {
      if (btn === 'shift') {
        if (data.active.includes(index)) {
          if (data.lastTarget > index) {
            for (
              let i = index;
              i <= data.lastTarget && data.active.length > 1;
              i++
            ) {
              Vue.set(
                data,
                'active',
                data.active.filter((x) => x !== i)
              )
            }
          } else {
            for (
              let i = data.lastTarget;
              i <= index && data.active.length > 1;
              i++
            ) {
              Vue.set(
                data,
                'active',
                data.active.filter((x) => x !== i)
              )
            }
          }
        } else {
          if (data.lastTarget > index) {
            for (let i = index; i <= data.lastTarget; i++) {
              if (!data.active.includes(i)) {
                Vue.set(data, 'active', [...data.active, i])
              }
            }
          } else {
            for (let i = data.lastTarget; i <= index; i++) {
              if (!data.active.includes(i)) {
                Vue.set(data, 'active', [...data.active, i])
              }
            }
          }
        }
      } else if (btn === 'ctrl') {
        if (data.active.includes(index) && data.active.length > 1) {
          Vue.set(
            data,
            'active',
            data.active.filter((x) => x !== index)
          )
        } else {
          Vue.set(data, 'active', [...data.active, index])
        }
      } else {
        Vue.set(data, 'active', [index])
      }
      data.lastTarget = index
      if (type.data.territories.length) getContracts(type)
    }

    const changeDoc = (type, val) => {
      type.data.docType = val
      type.data.items = []
      if (type.data.territories.length) getContracts(type)
    }

    const getContracts = async (type, refresh) => {
      Vue.set(
        type.data,
        'items',
        type.data.items.filter((item) => type.data.active.includes(item.index))
      )
      type.data.loading = true
      const request = type.data.active.reduce(
        (acc, typeIndex) => {
          if (!type.data.items.some((item) => item.index === typeIndex)) {
            acc.data.push(
              store.dispatch('form/getParams', {
                url: `get/${type.data.docType === 0 ? 'contract' : 'zones'}/${
                  type.data.territories[typeIndex].id
                }`,
              })
            )
            acc.i.push(typeIndex)
            acc.id.push(type.data.territories[typeIndex].id)
          }
          return acc
        },
        { data: [], i: [], id: [] }
      )
      await Promise.all(request.data).then((response) => {
        type.data.loading = false
        response.forEach((item, index) => {
          Vue.set(item, 'index', request.i[index])
          Vue.set(item, 'id', request.id[index])
          if (item.data.length) {
            item.data.forEach((pact) => {
              Vue.set(pact, 'loaded', null)
              Vue.set(pact, 'items', [])
            })
          }
        })
        type.data.items.push(...response)
      })
    }

    const newContractTemplate = {
      label: null,
      type: null,
    }
    const newContract = ref(_.cloneDeep(newContractTemplate))
    const closeDialog = () => {
      form.value = _.cloneDeep(formTemplate)
    }
    const createNewContract = async () => {
      const response = await store.dispatch('form/create', {
        url: 'create/contract',
        body: {
          data: {
            name: newContract.value.label,
            territory_id:
              form.value.type.data.territories[form.value.contract.index].id,
            type_id: newContract.value.type,
            type_object_id: 1,
          },
        },
      })
      if (response.code === 1) {
        refresh()
        closeDialog()
        newContract.value = _.cloneDeep(newContractTemplate)
      } else {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Название занято',
          timeout: 1000,
        })
      }
    }
    const addEntity = ({ type, zone, contract }) => {
      if (zone) {
        form.value.component = _.cloneDeep(zoneAdd)
        form.value.entity = 'zone'
        form.value.zone = zone
      } else if (contract) {
        form.value.component = null
        form.value.entity = 'contract'
        form.value.contract = contract
      } else {
        form.value.component = _.cloneDeep(territoryAdd)
        form.value.entity = 'territory'
      }
      form.value.type = type
      form.value.isShow = true
    }

    const parserClone = ref()
    const openParser = ({
      territory,
      contract,
      version,
      contract_id,
      contract_type,
    }) => {
      parserClone.value = _.cloneDeep(parserTarif)
      parserClone.value.fields[0].value = territory.id
      parserClone.value.fields[0].readonly = true
      parserClone.value.fields[1].value = contract.id
      parserClone.value.fields[1].readonly = true
      parserClone.value.fields[2].filter.push({
        alias: 'version',
        sendEmpty: true,
        value: version,
      })
      parserClone.value.fields[2].readonly = true
      parserClone.value.fields[3].value = contract_id
      parserClone.value.fields[4].value = contract_type
      parserClone.value.fields[5].value = contract.type_id

      parser.value = true
    }

    const autocompleteConfig = ref({
      items: [],
      label: 'Тип договора',
      selectOption: {
        text: 'name',
        value: 'id',
      },
    })

    const changeEntity = async (data, entity) => {
      const date_from = moment(data.data.date_from, 'YYYY.MM.DD').format(
        'YYYY-MM-DD'
      )
      const date_to = moment(data.data.date_to, 'YYYY.MM.DD').format(
        'YYYY-MM-DD'
      )
      if (entity.type === 'main') {
        loading.value = true
        expansion.value = []
      } else if (entity.type === 'zone') {
        entity.parent.data.loaded = false
        entity.parent.data.items.splice(
          entity.parent.data.items.findIndex(
            (x) => x.id === (entity.item.territory_id || entity.item.id)
          ),
          1
        )
      }

      const response = await store.dispatch('form/putForm', {
        url: `coefficient/change/${entity.type}`,
        body: {
          data: {
            date_from,
            date_to,
            coefficient: Number(data.data.coef),
            type_id: data.type_id,
            [entity.key]: entity?.item?.id,
          },
        },
      })
      if (response.code === 1) {
        store.commit('notifies/showMessage', {
          color: 'success',
          content: 'Коэффициент изменен',
          timeout: 1000,
        })
      } else {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Ошибка сервера',
          timeout: 1000,
        })
      }
      if (entity.type === 'main') {
        await getMainCoef()
        await getTypes()
      } else if (entity.type === 'territory') {
        getTerritories(
          types.value.findIndex((x) => x.id === entity.parent.id),
          true
        )
      } else if (entity.type === 'zone') {
        await getContracts(entity.parent)
        entity.parent.data.loaded = true
      }
    }

    const mainCoef = ref({})
    const getMainCoef = async () => {
      const response = await store.dispatch('form/get', 'get/coefficient/main')
      mainCoef.value = response.data
    }

    onMounted(async () => {
      getTypes()
      const list = await makeRequestList()
      autocompleteConfig.value.items = list.data.agreement_dictionary

      getMainCoef()
    })

    watch(
      () => expansion.value,
      (newVal, oldVal) => {
        let index
        if (newVal.length > oldVal.length)
          index = _.difference(newVal, oldVal)[0]
        else index = _.difference(oldVal, newVal)[0]
        if (index !== undefined && !types.value[index]?.data.loaded)
          getTerritories(index)
      },
      { deep: true }
    )
    return {
      loading,
      types,
      switchBtn,

      expansion,
      parser,
      parserClone,

      changeTerritory,
      changeDoc,
      openParser,

      form,
      refresh,
      addEntity,
      newContract,
      closeDialog,
      createNewContract,
      autocompleteConfig,
      changeEntity,
      mainCoef,
    }
  },
}
